:root{
	--right-space:26.75rem;
}
.wallet-open{
	.header{
		.page-titles,
		.header-content{
			padding-right: 1.875rem;
			@include transitionMedium;
			@include respond ('phone-land'){
				padding-right: 1.4rem;
			}
			@include respond ('phone'){
				padding-right: 1.1rem;
			}
		}
	}
	
	.content-body{
		padding-right: 0;
		@include transitionMedium;
	}
	.wallet-bar {
		right: -26.25rem;
	}

	&.active{
		.header{
			.page-titles,
			.header-content{
				padding-right: var(--right-space);
				@include custommq ($max:106.25rem){
					padding-right: 1.875rem;
					
				}
				@include respond ('phone'){
					padding-right: 12px;
				}
			}
		}
		.content-body{
			padding-right: calc(var(--right-space) - 1.75rem);
			@include transitionMedium;
			@include custommq ($max:106.25rem){
				padding-right: 0;
				
			}
		}
		.wallet-bar {
			right: 0rem;
		}
		.wallet-bar-close{
			position: fixed;
			width: 100%;
			cursor: pointer;
			height: 100%;
			top: 0;
			left: 0;
			background: rgba(0, 0, 0, 0.2);
			display:none;
			z-index: 1;
		}
	} 
}
.wallet-bar {
    position: fixed;
	box-sizing: content-box;
    width: 25rem;
	//padding: 2.375rem 15px;
	padding-bottom: 0;
	@include transitionMedium;
	top: 0px;
    z-index: 999;
    height:100%;
	scrollbar-width: none;
	box-sizing: border-box;
	@include respond ('laptop'){
		width: 23rem;
	}
	@include respond ('phone-land'){
		background:rgba(0,0,0,0.2);
		backdrop-filter:blur(5px);
		box-shadow:0 0 50px 0 rgba(0,0,0, 0.25);
		height:100vh;
		overflow-y:scroll;
	}
	.closed-icon{
		height:25px;
		width:25px;
		color: $main-color;
		font-size:20px;
		display: block;
		margin-bottom: 17px;
		cursor:pointer;
		display:none;
		@include respond ('phone'){
			display:block;
		}
	}
	.wallet-bar-scroll{
		height: 100%;
		padding: 2.375rem 15px;
		padding-bottom: 0;
		
	}
}