.simple-input {
    position: relative;
}

.simple-input input {
    color: #000;
    border-bottom: 1px solid black;
}

.simple-input input:not(:placeholder-shown) ~ span,
.simple-input input:focus ~ span {
    color: #000;
    transform: translateY(-1rem);
    font-size: 0.65rem;
}