.bg-orange-primary-50 {
    background-color: rgb(255, 246, 240);
}
.bg-orange-primary-600 {
    background-color: rgb(200, 107, 42);
}

.w-full {
    width: 100%;
}

.w-screen {
    width: 100vw;
}

.h-screen {
    height: 100vh;
}

.absolute {
    position: absolute;
}

.z-0 {
    z-index: 0;
}

.z-10 {
    z-index: 10;
}

.left-0 {
    left: 0px;
}

.h-11 {
    height: 2.75rem;
}

.umx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
}

.up-5 {
    padding: 1.25rem;
}

.umt-4 {
    margin-top: 1rem;
}

.mt-11 {
    margin-top: 2.75rem;
}

.mt-20 {
    margin-top: 5rem;
}

.flex {
    display: flex;
}

.flex-col {
    flex-direction: column;
}

.justify-center {
    justify-content: center;
}

.items-center {
    align-items: center;
}

.col-span-1 {
    grid-column: span 1 / span 1;
}

.col-span-2 {
    grid-column: span 2 / span 2;
}

img {
    max-width: 100%;
    height: auto;
    display: block;
}

@keyframes moving_background_login {
    0% { background-position: 0 0; }
    100% { background-position: 50% 0; }
}

.animation_moving_background_login {
    animation: moving_background_login 10s linear infinite alternate;
}

@keyframes moving_letter {
    0% { transform: translateX(0px); }
    100% { transform: translateX(-200px); }
}

.animation-moving_letter {
    animation: moving_letter 10s ease-in-out infinite alternate;
}

@keyframes moving_circle {
    0% { left: 10rem; top: -2rem; }
    100% { left: 45%; top: 45%; }
}

.animation-moving_circle {
    animation: moving_circle 10s ease-in-out infinite alternate;
}

.backdrop-blur-lg {
    backdrop-filter: blur(16px);
}

.rounded-xl {
    border-radius: 0.75rem;
}

.border-none {
    border-style: none;
}

.outline-none {
    outline: 2px solid transparent;
}

.ushadow-none {
    box-shadow: 0 0 #0000, 0 0 #0000, 0 0 #0000;
}

.text-base {
    font-size: 1rem;
    line-height: 1.5rem;
}

.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}

.not-italic {
    font-style: normal;
}

.font-light {
    font-weight: 300;
}

.tracking-widest {
    letter-spacing: 0.1em;
}

.uppercase {
    text-transform: uppercase;
}

.pointer-events-none {
    pointer-events: none;
}

.duration-500 {
    transition-duration: 500ms;
}

@media (min-width: 1024px) {
    .lg-grid {
        display: grid;
    }

    .lg-grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .lg-items-stretch {
        align-items: stretch;
    }

    .lg-mx-0 {
        margin-left: 0px;
        margin-right: 0px;
    }

    .lg-rounded-none {
        border-radius: 0px;
    }
}