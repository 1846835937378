// Ckeditor
.ck.ck-editor{
	.ck.ck-button{
		padding: 6px 8px;
		background-color: var(--rgba-primary-1);
		color: var(--primary);
		font-weight: 900;
		
	}
	.ck.ck-toolbar{
		background-color: var(--bs-card-bg);
		border: 0;
		padding: 5px 10px;
	}
	.ck.ck-toolbar__separator{
		display: none !important;
	}
	.ck-content{
		background-color: #f7f7f7;
		border-width: 1px 0 0;
		border-color: #e7e5ef;
	}
}
.ck-editor__editable{
	background-color:	var(--bs-card-bg)!important;	
}
.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred {
    border-color: $b-color;
	padding: 0.865rem var(--ck-spacing-standard);
}
.ck.ck-toolbar .ck-toolbar__items .ck.ck-toolbar__separator {
    background: $b-color;
}
.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred {
	min-height: 9.375rem;
}
button.ck.ck-button.ck-off:hover {
    background-color: $b-color;
}
.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused{
	min-height: 9.375rem;
}
